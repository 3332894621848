body {
  margin: 0;
  font-family: ocr-a-std,
    monospace;
  font-style             : normal;
  font-weight            : 400;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-size : 40px 40px;
  background-color: white;
  transition      : background-color 0.5s ease;
  background-image: linear-gradient(to right, grey 1px, transparent 1px),
    linear-gradient(to bottom, grey 1px, transparent 1px);
}

html {
  font-size: 17px;
}

#text {
  transition: color 0.5s ease;
}


.wrapper {
  display: grid;
  grid-template-areas:
    "left-side"
    "content"
    "color-nav-container"
    "footer";
}

.progressBar {
  display        : flex;
  flex-flow      : row nowrap;
  justify-content: space-between;
}

.left-side {
  grid-area: left-side;
}

.content {
  grid-area      : content;
  height         : 88vh;
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
}

.logo {
  font-size    : 10rem;
  margin-bottom: 2rem;
}

.updates {
  font-size: 2rem;
  opacity  : 0.9;
}

.color-nav-container a {
  grid-area    : color-nav-container;
  align-items  : center;
  position     : absolute;
  cursor       : pointer;
  /* Pointer/hand icon */
  display      : block;
  right        : 0px;
  /* Position them outside of the screen */
  transition   : 0.3s;
  /* Add transition on hover */
  padding      : 1vh;
  width        : 4vw;
  /* Set a specific width */
  height       : 3vh;
  border-radius: 5px 0px 0px 5px;
  /* Rounded corners on the top right and bottom right side */

  background-size: 10px 10px;
}

.color-nav-container a:hover {
  width: 6vw;
  /* On mouse-over, make the elements appear as they should */
}


.footer {
  grid-area      : footer;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  text-align     : center;
  width          : 100%;
}


.form {
  padding-bottom: 20px;
}

.form-intro {
  font-size     : 1rem;
  opacity       : 0.9;
  padding-bottom: 1rem;
}


/* Style the form - display items horizontally */
.inputForm {
  font-family: inherit;
  display    : flex;
  flex-flow  : row wrap;
  align-items: center;
}


/* Style the input fields */
.inputForm input {
  font-family     : inherit;
  vertical-align  : middle;
  margin          : 5px 10px 5px 0;
  padding         : 10px;
  background-color: #fff;
  border          : 1px solid #ddd;
  border-radius   : 5px 5px 5px 5px;
}

.inputFrom input:-internal-autofill-selected {
  background-color: lightgrey
}

/* Style the submit button */
.inputForm button {
  font-family     : inherit;
  padding         : 10px 20px;
  margin          : 5px 10px 5px 0;
  background-color: white;
  border          : 1px solid #ddd;
  color           : black;
  border-radius   : 5px 5px 5px 5px;
}

.inputForm button:hover {
  background-color: #D8D7DF;
  color           : white;
}


.confirmation {
  background-color: white;
  color           : black;
  width           : 100vw;
}

a {
  color: grey;
}

@media (max-width: 940px) {
  body {
    background-size: 20px 20px;
  }

  html {
    font-size: 10px;
  }

  .wrapper {
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-areas  :
      "left-side content color-nav-container"
      "footer footer footer"
  }

  .content {
    height: 70vh;
  }

  .footer {
    height: 30vh
  }

  .form {
    padding-bottom: 0;
  }

  .inputForm input {
    padding-bottom: 10px 0;
  }

  .inputForm {
    flex-direction: column;
    align-items   : stretch;
  }

  .inputForm button {
    margin-top   : 10px;
    margin-bottom: 20px;
  }

  .form-intro {
    font-size: 2rem;
  }

  .confirmation {
    font-size: 10px;
  }
}

@media (max-width: 630px) {
  html {
    font-size: 6px;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 4px;
  }

  .confirmation {
    font-size: 8px;
  }
}